<template>
    <div class="container-fluid d-flex flex-column flex-grow-1 mt-3">
        <div class="animated fadeIn">
            <b-row>
                <b-col lg="12">
                    <transition name="fade">
                        <b-card no-body>
                            <div slot="header">
                                <i class="fa fa-edit"></i> Add Category
                                <div class="card-header-actions">
                                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1><i class="icon-arrow-up"></i></b-link>
                                </div>
                            </div>
                            <b-collapse id="collapse1" visible>
                                <b-card-body>
                                    <b-row>
                                        <b-col sm="4">
                                            <TextBox v-model="item.name" placeholder="Name"
                                                     :addClass="{'is-invalid': $v.item.name.$error}">Name
                                            </TextBox>
                                        </b-col>
                                        <b-col sm="4">
                                            <TextBox v-model="item.name_ru" placeholder="Name">Name Ru</TextBox>
                                        </b-col>
                                        <b-col sm="4">
                                            <TextBox v-model="item.name_en" placeholder="Name">Name En</TextBox>
                                        </b-col>
                                        <b-col sm="4">
                                            <TextBox v-model="item.sorder" placeholder="Name">Sorder</TextBox>
                                        </b-col>
                                        <b-col sm="4">
                                            <SelectBox :items="categories" v-model="selectedCategory">Parent</SelectBox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col sm="6">
                                            <h3>Logo</h3>
                                            <div class="profile-img-upload">
                                                <img v-if="logoUrl" :src="logoUrl" alt="img" style="width: 150px">
                                                <img v-else src="../../assets/img/no-image.png" alt="img" style="width: 150px">
                                                <input type="file" style="display: none"
                                                       id="logoImgUpload"
                                                       v-on:input="onLogoChoose">
                                                <label for="logoImgUpload">
                                            <span class="profile-img-upload-title">
                                                <i class="fa fa-camera mr-2"></i>
                                                <span style="cursor: pointer">Change</span>
                                            </span>
                                                </label>
                                            </div>
                                        </b-col>
                                        <b-col sm="6">
                                            <h3>Svg</h3>
                                            <div class="profile-img-upload">
                                                <img v-if="svgUrl" :src="svgUrl" alt="img" style="width: 150px">
                                                <img v-else src="../../assets/img/no-image.png" alt="img" style="width: 150px">
                                                <input type="file" style="display: none"
                                                       id="svgImgUpload"
                                                       v-on:input="onSvgChoose">
                                                <label for="svgImgUpload">
                                            <span class="profile-img-upload-title">
                                                <i class="fa fa-camera mr-2"></i>
                                                <span style="cursor: pointer">Change</span>
                                            </span>
                                                </label>
                                            </div>
                                        </b-col>
                                    </b-row>
                                    <div class="d-flex justify-content-end">
                                        <div class="form-actions">
                                            <b-button right type="submit" variant="primary" @click="onSubmit">Save</b-button>
                                            <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/categories'">Cancel</router-link>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </transition>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
  import {required} from 'vuelidate/lib/validators';

  export default {
    name: 'CategoryAddEditPage',
    data() {
      return {
        item: {
          name: ''
        },
        categories: [],
        selectedCategory: null,
        logoUrl: '',
        svgUrl: ''
      };
    },
    created() {
      this.loadRelatedData();
    },
    computed: {},
    methods: {
      async loadRelatedData() {
        let [categoryReq] = await Promise.all([
          this.axios.get('public/reference/categories')
        ]);
        if (categoryReq.data && categoryReq.data.data) {
          this.setSelectedItems(categoryReq.data.data, 'categories');
        }

      },
      setSelectedItems(items, code) {
        const mType = items.map(item => {
          return {text: item.name, value: item.id + ''};
        });
        mType.unshift({value: null, text: 'Select'});
        this[code] = mType;
      },
      onLogoChoose (event) {
        this.selectedFile = event.target.files[0]
        const formData = new FormData()
        formData.append('uploadFile', this.selectedFile)

        this.axios.post('public/upload/file',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        )
            .then((response) => {
              if (response.data && response.data.data) {
                this.item.logo = {id: response.data.data.id}
                this.logoUrl = response.data.data.imageURL
              }
            })
      },
      onSvgChoose (event) {
        this.selectedFile = event.target.files[0]
        const formData = new FormData()
        formData.append('uploadFile', this.selectedFile)

        this.axios.post('public/upload/category-svg',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        )
            .then((response) => {
              if (response.data && response.data.data) {
                this.item.svgLogo = {id: response.data.data.id}
                this.svgUrl = response.data.data.imageURL
              }
            })
      },
      onSubmit() {
        if (this.$v.item.$invalid) {
          this.$v.$touch();
          return;
        }
        this.selectedCategory
            ? this.item.parent = {id: this.selectedCategory}
            : this.item.parent = null
        let axios = this.axios.post('category/item', this.item);
        axios.then((res) => {
          if (res.data) {
            this.$toastr.info('Successfully saved');
            this.$router.push('/categories');
          } else {
            this.$toastr.error(res.data.message);
          }
        });
      }
    },
    validations: {
      item: {
        name: {
          required
        }
      }
    }
  };
</script>

<style scoped>

</style>